import React from "react"
import { Link } from "gatsby";
import { globalHistory } from "@reach/router";
import Layout from "../components/Layout";

const NotFoundPage = ({ pageContext: { data: { content } } }) => {
  const langFromQuery = globalHistory?.location.pathname.split('/')[1];
  
  return (
    <Layout>
    <div className='NotFoundPageContainer'>
      <div className='NotFoundPageBox' >

        <h1>{content.title}</h1>
        <h2>{content.subtitle}</h2>
        <p>{content.text}</p>
        {content.button_text &&
          <Link
            className="btn btn-lg btn-primary rounded-pill mt-1"
            role="button"
            to={`${globalHistory.location.origin}/${langFromQuery}/`}
          >
            {content.button_text}
          </Link>
        }
      </div>
    </div>
    </Layout>
  )
}


export default NotFoundPage
